import BaseComponent from './basecomponent';
// import $ from 'jquery';

export default class GiftMessage extends BaseComponent {
  constructor(element) {
    super(element);

    this.form = element.querySelector('form');
    this.textarea = element.querySelector('textarea');

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
    });

    this.buttons = this.form.querySelectorAll('button.action-submit');

    this.buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        $.ajax({
          method: this.form.method,
          url: this.form.getAttribute('action'), // NOTE: force use getAttribute instead of this.form.action
          data: {
            giftMessage: this.textarea.value,
            action: e.currentTarget.value
          },
          success: (data) => {
            this.textarea.value = data.giftMessage;
          }
        });
      });
    });
  }

  _destroy() {
    console.log('destroying component LoginRegistration');
  }
}
