const IS_SPECIAL_REGEXP = /^(sel|var|ary):(.*)$/;

function isSpecial(value) {
  var matches = value.match(IS_SPECIAL_REGEXP);
  return matches && matches.length > 1;
}
function isSelector(value) {
  var matches = value.match(IS_SPECIAL_REGEXP);
  if (matches[1] == 'sel') return matches[2];
}
function isVariable(value) {
  var matches = value.match(IS_SPECIAL_REGEXP);
  if (matches[1] == 'var') return matches[2];
}
function isArray(value) {
  var matches = value.match(IS_SPECIAL_REGEXP);
  if (matches[1] == 'ary') return matches[2];
}

export default {
  NAME: 'JsonDa',

  data(el, key) {
    let itemdata = el[`__data_${key}`];
    if (!itemdata) {
      itemdata = this.nsData(el, key);
      if (typeof itemdata === 'undefined') {
        itemdata = {};
      }
      el[`__data_${key}`] = itemdata;
    }
    return itemdata;
  },

  nsData(el, namespace) {
    let json = {};

    if (!el || !el.attributes) {
      console.error("'el' is not a valid HTML element");
      return json;
    }

    let arr_attrs = Array.prototype.slice.call(el.attributes || [], 0);
    for (const attr of arr_attrs) {
      let attr_name = attr.name,
        value = attr.value;
      if (attr_name.indexOf('data-') == 0) {
        // found a data- attribute
        let names = attr_name.split('-').slice(1),
          obj = json,
          name,
          original_data_attribute = [];

        while ((name = names.shift())) {
          // trasformation in camelCase
          let nn = name.split(/(?=_)_/);
          let camelCase = true;
          for (let z = 1; z < nn.length; z++) {
            let _nn = nn[z];
            if (!_nn) {
              nn[z] = '_';
              camelCase = false;
              continue;
            } else if (camelCase) {
              _nn = _nn.substring(0, 1).toUpperCase() + _nn.substring(1);
              nn[z] = _nn;
            }
            camelCase = true;
          }
          name = nn.join('');

          original_data_attribute.push(name);

          if (!names.length) {
            // last element, we have to calculate its value
            obj[name] = function() {
              // check special attribute
              let selvar;
              if (isSpecial(value)) {
                if ((selvar = isSelector(value))) {
                  let subel = document.querySelector(selvar);
                  let json_da_subel = this.nsData(subel, original_data_attribute.join('.'));
                  if (typeof json_da_subel == 'object' && Object.keys(json_da_subel).length <= 0) {
                    json_da_subel = '';
                  }
                  if (!json_da_subel) {
                    json_da_subel = subel.value;
                  }
                  value = json_da_subel;
                } else if ((selvar = isVariable(value))) {
                  value = extractValueFromVar(selvar);
                } else if ((selvar = isArray(value))) {
                  // collect array from DOM following the `original_data_attribute` as rootscope
                  let children = el.querySelectorAll(selvar);
                  value = [];
                  for (let i_c = 0, c; (c = children[i_c++]); ) {
                    let json_da_child = this.nsData(c, original_data_attribute.join('.'));
                    value.push(json_da_child);
                  }
                }
              }

              if (value == 'true') {
                value = true;
              } else if (value == 'false') {
                value = false;
              } else if (value == 'null') {
                value = null;
              } else if (value == 'undefined') {
                value = undefined;
              }

              // if (typeof value === 'string' && value !== '' && (value == '0' || value.startsWith('0.') || value.charAt(0) != '0')) {
              //   // try to convert into Number
              //   let int_value = Number(value);
              //   value = isNaN(int_value) ? value : int_value;
              // }

              return typeof obj[name] !== 'object' ? value : obj[name];
            }.apply(this);
          } else {
            // create a temporary object to store data
            obj[name] = typeof obj[name] !== 'object' ? {} : obj[name];
            obj = obj[name];
          }
        }
      }
    }

    return namespace
      ? (function() {
          let keys = namespace.split('.'),
            k,
            o = json;
          while (o && (k = keys.shift()) && o[k]) {
            o = o[k];
          }
          while (keys.length > 1 && (k = keys.shift())) {
            o = o[k] = {};
          }
          return o;
        })()
      : json;
  }
};

function extractValueFromVar(selvar) {
  let steps = selvar.split('.');
  let last = steps.pop();
  let step = null;
  let obj = window;
  while ((step = steps.shift()) && (obj = obj[step])) {}
  return obj[last];
}
