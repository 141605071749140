import GTM from './gtm';
import JsonDA from './json_da';

const STEPS = {
  shipping: 3,
  payment: 4,
  placeOrder: 5
};

const hash = window.location.hash.substring(1);

let CHECKOUT_STEP = STEPS[hash] || STEPS.shipping;

export default class CheckoutGTM extends GTM {
  constructor(element) {
    super(element);
  }

  init() {
    $(document.body).on('checkout:updateCheckoutView', this.onEvent.bind(this));
  }

  onEvent(e, data) {
    if (this.Data.gtmEcommerceCheckoutAction_fieldStep != `${CHECKOUT_STEP}`) {
      console.log(`GTM will skipped`, this.element);
      return;
    }

    window.GTMVAR = data;
    try {
      window.GTMVAR.PAYMENT_METHOD = data.order.billing.payment.selectedPaymentMethods[0];
    } catch (e) {
      console.warn('no payment method found');
    }
    this.jsondata = JsonDA.nsData(this.element, 'gtm');

    window.dataLayer.push(this.jsondata);

    setTimeout(() => {
      CHECKOUT_STEP++;
    }, 500);
  }

  _destroy() {}
}
