const allComponents = {};

class BaseComponent {
  get isGlobal() {
    return false;
  }

  get PublicStaticPath() {
    return document.documentElement.dataset.publicpath;
  }

  get Data() {
    if (this.__data) {
      return this.__data;
    }

    const compOptions = {};
    const options = { ...this.element.dataset };
    const optionsEntires = Object.keys(options);
    optionsEntires.forEach((entry) => {
      const cleanEntry = entry;
      let optionValue;
      if (options[entry].includes('{')) {
        optionValue = JSON.parse(options[entry].replace(/'/g, '"'));
      } else {
        optionValue = this.convertType(options[entry]);
      }
      compOptions[cleanEntry] = optionValue;
    });
    return (this.__data = compOptions);
  }

  get Name() {
    return this._name;
  }

  getComponent(key) {
    return allComponents[key];
  }

  constructor(element) {
    this.element = element;

    const name = this.constructor.name;
    this._name = name.split('_').pop();

    this.__data = null;

    console.log('Executing component', this.Name, 'marked as global', this.isGlobal);

    if (this.isGlobal) {
      allComponents[this.Name] = this;
    }
  }

  convertType(value) {
    let optionReturn;
    switch (value) {
      case 'false':
        optionReturn = false;
        break;
      case 'true':
        optionReturn = true;
        break;
      case 'null':
        optionReturn = null;
        break;
      default:
        optionReturn = value;
        break;
    }

    return optionReturn;
  }
}

export default BaseComponent;
