import BaseComponent from './basecomponent';
import JsonDA from './json_da';

export default class GTM extends BaseComponent {
  constructor(element) {
    super(element);

    if (window.dataLayer) {
      this.init();
    } else {
      console.warn(`+++ cannot find dataLayer variable`);
    }
  }

  init() {
    if (this.Data.eventGtm === 'elementloaded') {
      this.onEvent();
    } else {
      $(this.Data.eventGtmElement || this.element).on(this.Data.eventGtm || 'click', this.onEvent.bind(this));
    }
  }

  onEvent(e, data) {
    window.GTMVAR = data;
    this.jsondata = JsonDA.nsData(this.element, 'gtm');
    window.dataLayer.push(this.jsondata);
  }

  _destroy() {}
}
