import BaseComponent from './basecomponent';

export default class FormValidation extends BaseComponent {
  constructor(element) {
    super(element);

    this.element.setAttribute('novalidate', 'novalidate');

    this.element.addEventListener('submit', this.onSubmit.bind(this));
  }

  onSubmit(e) {
    let requiredEls = Array.prototype.slice.call(this.element.querySelectorAll('[required]'), 0);

    let errorField = null;
    for (let el of requiredEls) {
      el.classList.remove('is-invalid');
      if (el._error_container) {
        el._error_container.remove();
        delete el._error_container;
      }

      if (!el.checkValidity()) {
        errorField = errorField || el;

        let parentEl = el.parentElement;
        let errorEl = parentEl.querySelector('.invalid-feedback');
        if (errorEl && errorEl.innerHTML.trim()) {
          // already managed
          continue;
        }

        let p = errorEl || document.createElement('div');
        p.setAttribute('data-color', 'danger');
        p.classList.add('invalid-feedback');
        p.innerHTML = el.dataset.errorMessage || el.validationMessage;

        el.parentElement.appendChild(p);
        el.classList.add('is-invalid');

        el._error_container = p;
      }
    }

    if (errorField) {
      try {
        errorField.scrollIntoView({
          behavior: 'smooth'
        });
      } catch (e) {
        console.warn(`scroll in to view error for`, errorField);
      }
      e.preventDefault();
    }

    return !errorField;
  }

  _destroy() {}
}
