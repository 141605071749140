import BaseComponent from './basecomponent';

const PROPS_CHANGE_EVENT = ['value', 'checked'];

export default class Watcher extends BaseComponent {
  constructor(element) {
    super(element);
    this.watchedEl = $(this.Data.watchedElement)[0];
    // this.Data.watchedPropertyName
    // this.Data.watchedPropertyValue
    this.bindMutation();
  }

  bindMutation() {
    const config = { attributes: true, childList: false, subtree: false };
    this.observer = new MutationObserver(this.onMutation.bind(this));
    this.observer.observe(this.watchedEl, config);

    if (PROPS_CHANGE_EVENT.indexOf(this.Data.watchedPropertyName) > -1) {
      var oldValue = { value: this.watchedEl[this.Data.watchedPropertyName] };
      this.watchedEl.addEventListener('change', this.onChange.bind(this, oldValue));
    }
  }

  onChange(oldvalue) {
    if (String(oldvalue.value) != String(this.watchedEl[this.Data.watchedPropertyName])) {
      this.executeChange(this.watchedEl[this.Data.watchedPropertyName]);
      oldvalue.value = this.watchedEl[this.Data.watchedPropertyName];
    }
  }

  onMutation(mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        this.executeChange(this.watchedElement[this.Data.watchedPropertyName]);
      }
    }
  }

  executeChange(value) {
    this['executeChange' + this.Data.elementProperty](value);
  }

  executeChangedisabled(value) {
    this.element.disabled = String(value) != String(this.Data.watchedPropertyValue);
  }

  _destroy() {
    this.observer.disconnect();
  }
}
