import BaseComponent from './basecomponent';

export default class FormRemote extends BaseComponent {
  constructor(element) {
    super(element);

    if (this.Data.replaceElement) {
      this.replaceElement = $(this.Data.replaceElement);
    }

    this.bindEvents();
  }

  bindEvents() {
    this.element.addEventListener('submit', this.onSubmit.bind(this));
  }

  onCompleteAction(xhr, error) {
    let data = xhr.responseJSON ? xhr.responseJSON.message : xhr.responseText;
    error = error || (xhr.responseJSON ? xhr.responseJSON.error : false);

    if (data.redirectUrl) {
      location.href = data.redirectUrl;
    }

    if (this.replaceElement && this.replaceElement.length) {
      this.replaceElement.html(data);
      if (error) {
        this.replaceElement.addClass('error');
      }
    } else if (this.Data.onSuccess == 'close-dialog') {
      $(this.element)
        .closest('[role="dialog"]')
        .modal('close');
    }
  }

  onSubmit(e) {
    e.preventDefault();

    let url = this.element.action;
    let method = this.element.method || 'get';
    let data = $(this.element).serialize();

    let _self = this;

    $.ajax({
      url,
      method,
      data,
      beforeSend() {
        if (_self.replaceElement && _self.replaceElement.length) {
          _self.replaceElement.removeClass('error');
        }
        $(_self.element)
          .find('.is-invalid')
          .removeClass('is-invalid');
      },
      success(data, arg1, xhr) {
        _self.onCompleteAction(xhr, false);
      },
      error(xhr) {
        _self.onCompleteAction(xhr, true);
      }
    });
  }

  _destroy() {}
}
