import { ComponentDispatcher } from 'html-scoped-component-importer';

import GiftMessage from './es6-components/gift-message';
import FormatDate from './es6-components/format-date';
import GTM from './es6-components/gtm';
import FormRemote from './es6-components/form-remote';
import FormNewsletterRemote from './es6-components/form-newsletter-remote';
import Watcher from './es6-components/watcher';
import ModalHandler from './es6-components/modal-handler';
import FormValidation from './es6-components/form-validation';
import CheckoutGTM from './es6-components/checkout-gtm';

const compDispatcher = new ComponentDispatcher();

compDispatcher.compsObj = {
  GiftMessage,
  FormatDate,
  GTM,
  FormRemote,
  Watcher,
  ModalHandler,
  FormValidation,
  FormNewsletterRemote,
  CheckoutGTM
};

compDispatcher.createComponents(compDispatcher.compsObj);

compDispatcher.observeDomChanges();

$(document).on('components.reloadhtml', (e, el) => {
  compDispatcher.importComponents(compDispatcher.compsObj, el);
});
