import BaseComponent from './basecomponent';

export default class ModalHandler extends BaseComponent {
  constructor(element) {
    super(element);

    this.element.addEventListener(this.Data.modalEvent || 'click', this.onClick.bind(this));

    this.genericModal = $('#generic-modal');
  }

  onClick(e) {
    let modalTarget = $(this.Data.target);

    let header = modalTarget.find('.js-modal-header');
    let body = modalTarget.find('.js-modal-body');
    let footer = modalTarget.find('.js-modal-footer');

    let genericHeader = this.genericModal.find('.modal-header');
    let genericBody = this.genericModal.find('.modal-body');
    let genericFooter = this.genericModal.find('.modal-footer');

    genericHeader.html(header.html());
    genericBody.html(body.html());
    genericFooter.html(footer.html());

    $(document).triggerHandler('components.reloadhtml', this.genericModal[0]);

    this.genericModal.addClass(modalTarget[0].className).modal('show');

    if(genericBody.find('#g-recaptcha-modal').length){
      var sitekey = $('.grec-info').data('skey');
      var opt_widget_id = grecaptcha.render('g-recaptcha-modal', {"sitekey" : sitekey});
      genericBody.find('#g-recaptcha-modal').data('wid', opt_widget_id);
    }
  }

  _destroy() {}
}
