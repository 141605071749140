import FormRemote from './form-remote';

export default class FormNewsletterRemote extends FormRemote {
  constructor(element) {
    super(element);

    let alreadySetEmail = $('#newsletter-input').val();

    this.element.querySelector('#newsletter-mail').value = this.element.querySelector('#newsletter-mail').value || alreadySetEmail;
  }

  bindEvents() {
    this.element.addEventListener('submit', this.onSubmit.bind(this));
  }

  onCompleteAction(xhr, error) {
    let data = xhr.responseJSON ? xhr.responseJSON.message : xhr.responseText;
    error = error || (xhr.responseJSON ? xhr.responseJSON.error : false);

    if (data.redirectUrl) {
      location.href = data.redirectUrl;
    }
    var label = $(this.element.parentElement).find($(this.element).data('replaceElement'));
    if (label && label.length) {
      label.html(data);
      if (error) {
        label.addClass('error');
      }
    } else if (this.Data.onSuccess == 'close-dialog') {
      $(this.element)
        .closest('[role="dialog"]')
        .modal('close');
    }
  }

  onSubmit(e) {
    var captcha = $('.newsletter-modal.show #g-recaptcha-modal');
    if (captcha.length) {
      var captchaWID = captcha.data('wid');
      var gTokenClient = grecaptcha.getResponse(captchaWID);
      var action = $('.grec-info').data('uri');
      var captchaDone = false;
      if (gTokenClient == '') {
        captcha.addClass('is-invalid');
        captcha.siblings('.invalid-captcha').text('Bisogna accettare il captcha!');
        console.log('Non è stato premuto il captcha!!');
        e.preventDefault();
        return;
      } else {
        $.ajax({
          url: action,
          async: false,
          method: 'GET',
          data: { gTokenClient: gTokenClient },
          success: function(data) {
            if (data.success) {
              console.log("Accepted Cap");
              captchaDone = true;
            } else {
              captcha.addClass('is-invalid');
              captcha.siblings('.invalid-feedback').text('ERROR Captcha: Captcha scaduto! Riavviare');
              console.log(data.errorMessage);
              var button = $(e.target).find(':submit');
              button.attr('disabled', true);
              e.preventDefault();
              return;
            }
          },
          error: function(e) {
            console.log('error captcha!!');
          }
        });
      }
    }

    if (captchaDone) {
      e.preventDefault();
      let url = this.element.action;
      let method = this.element.method || 'get';
      let data = $(this.element).serialize();

      let _self = this;

      $.ajax({
        url,
        method,
        data,
        beforeSend() {
          var label = $(_self.element.parentElement).find($(_self.element).data('replaceElement'));
          if (label && label.length) {
            label.removeClass('error');
          }
          $(_self.element)
              .find('.is-invalid')
              .removeClass('is-invalid');
        },
        success(data, arg1, xhr) {
          _self.onCompleteAction(xhr, false);
        },
        error(xhr) {
          _self.onCompleteAction(xhr, true);
        }
      });
    }
  }
  _destroy() {}
}
