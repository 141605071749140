import BaseComponent from './basecomponent';
import Moment from 'moment';
import 'moment/locale/it';

export default class FormatDate extends BaseComponent {
  constructor(element) {
    super(element);
    let format = this.Data.format;

    let value = this.Data.date;
    // let values = value.split('.');
    // values.pop();
    // value = values.join('.');

    let datestr = Moment.utc(value);
    this.element.innerText = datestr.format(format || 'dd MM yyyy');
  }

  _destroy() {}
}
